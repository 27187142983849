import React from 'react';


import { CSSTransition } from 'react-transition-group';
import { range } from 'lodash-es';

import Helmet from './common/Helmet'

import { history } from '../history'

import {
  baseDomain,
  recordLinkClickGA,
  ProductionGlossaryBaseUrl,
} from '../utils'

import {
  NeuroFitNamedLogoSvg,
} from '../constants/svgs'

import i18next from 'i18next'

import StaticArticleRecommendation from './common/StaticArticleRecommendation'

const containerStyle = {textAlign: "start", height: "100%", width: "100vw", overflowX: "hidden", overflowY: "scroll"}



class LearnLocalizedSitemapPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      learnMoreRef: React.createRef(),
      headerColor: "transparent",
      dropShadowColor: "transparent",
      show: true,
      showIframe: false,
      selectedCategory: "",
      searchQueryText: "",
      searchBarFocused: false,
      searchBarHasFocused: false,
    }
  }

  handleOnClickLearnMore = (event) => {
      //.current is verification that your element has rendered
      if(this.state.learnMoreRef.current){
          this.state.learnMoreRef.current.scrollIntoView({ 
             behavior: "smooth", 
             block: "nearest"
          })
      }
  }

  updateHeaderColorOnContainerScroll = e => {
    if (e.target.scrollTop > 0) {
      const alpha = Math.min(0.5, e.target.scrollTop / 500)
      const headerColor = `rgba(255, 255, 255, ${alpha/1.5})`
      const dropShadowColor = `rgba(0, 0, 0, ${alpha/3.0})`
      this.setState({headerColor, dropShadowColor/* `rgba(53, 63, 106, ${alpha}`*/})
    } else {
      this.setState({headerColor: "transparent", dropShadowColor: "transparent"})
    }

    if (e.target.scrollTop > 400) {
      this.setState({showIframe: true})
    }
  }

  componentDidMount() {
    this.setState({show: true})
    this.scrollingContainerRef.addEventListener('scroll', this.updateHeaderColorOnContainerScroll)
  }

  render() {

    const {
      safeAreaInsets,
      innerHeight,
      formFields,
      isNative,
      languageCode,
      articlePreviews
    } = this.props

    const {show, learnMoreRef, scrollingContainerRef, headerColor, dropShadowColor, showIframe, selectedCategory, searchQueryText, searchBarFocused, searchBarHasFocused} = this.state

    const articleCategories = [
      "lifestyle",
      "habits",
      "nervous system fitness",
      "neuroscience",
      "symptoms",
      "dysregulation"
    ]

    const articleCategoryObjects = articleCategories.map(c => ({
      key: c,
      value: i18next.t(c)
    }))

    let reCategory = new RegExp(selectedCategory, "i")
    let reSearch = new RegExp(searchQueryText, "i")

    const categoryFilteredArticlePreviews = (selectedCategory !== "")
        ? articlePreviews.filter(preview => reCategory.test(preview.category_tags)) 
        : articlePreviews

    const finalFilteredArticlePreviews = (
            (searchQueryText !== "")
                ? categoryFilteredArticlePreviews.filter(preview => (reSearch.test(preview.title) || reSearch.test(preview.description))) 
                : categoryFilteredArticlePreviews
          )

    return (
      <div style={{height: innerHeight}}>
        <Helmet
          title={i18next.t('page_title')}
          description={i18next.t('page_description')}
          href={ProductionGlossaryBaseUrl}
        />
        <div style={{position: "fixed", left: 0, top: 0, height: innerHeight, overflow: "hidden", backgroundColor: "#EDEEF2"}}>
          <div style={{position: "fixed", top: 0, left: 0, right: 0, height: safeAreaInsets.top, backgroundColor: "#000000", zIndex: 2000}} />
          <div style={{position: "fixed", top: 0, left: 0, right: 0, boxShadow: "0px 2px 0px 0px black", display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: 12 + safeAreaInsets.top, paddingInlineStart: "min(40px, 7.5%)", paddingInlineEnd: "min(20px, 4%)", paddingBottom: 12, backgroundColor: "white", zIndex: 1000}}>
            <NeuroFitNamedLogoSvg
              logoSize={"min(4.25vw, 18px)"}
              color={"#000000"}
              onClick={() => window.location = "/"}
            />
          </div>
          <div 
            id={"landing-page-container"}
            className={"disable-scrollbars"}
            ref={elem => this.scrollingContainerRef = elem}
            style={containerStyle}
          >
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top", marginTop: "calc(60px + 5vh)"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <h1 style={{marginTop: 20, fontSize: "min(8vw, 40px)", textAlign: "start", fontFamily: "Futura", textTransform: "uppercase"}}>
                    {i18next.t('header_text')}
                  </h1>
                  <h2 style={{marginTop: 20, fontSize: "min(6vw, 26px)", textAlign: "start", fontFamily: "Futura Book"}}>
                    {i18next.t('subheader_text')}
                  </h2>
                  <div style={{width: "100%", maxWidth: "600px", display: "flex", alignItems: "center", backgroundColor: "#FFFFFF", boxShadow: "0px 0px 2px 1.5px #CFD2DD inset"}}>
                    <CSSTransition
                      in={(searchQueryText === "") && (!searchBarFocused)}
                      timeout={250}
                      classNames="search-icon-transition"
                    >
                      <svg style={{height: "min(4vw, 18px)", width: searchBarHasFocused ? undefined : "min(4vw, 18px)", marginInlineEnd: 8, marginInlineStart: searchBarHasFocused ? undefined : 8}} width="449px" height="449px" viewBox="0 0 449 449" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><g id="noun-684728" fill="#999999" fillRule="nonzero"><path d="M439.599,400.401 L305.199,265.301 C324.101,238 336,204.399 336,168 C336,75.602 260.398,-2.84217094e-14 168,-2.84217094e-14 C75.602,-2.84217094e-14 2.84217094e-14,75.602 2.84217094e-14,168 C2.84217094e-14,260.398 75.602,336 168,336 C204.398,336 238,324.102 265.301,305.199 L400.401,440.299 C411.6,451.498 429.1,451.498 440.299,440.299 C450.799,429.1 450.799,410.897 439.59978,400.397 L439.599,400.401 Z M55.999,168.001 C55.999,106.399 106.397,56.001 167.999,56.001 C229.601,56.001 279.999,106.399 279.999,168.001 C279.999,229.603 229.601,280.001 167.999,280.001 C106.397,280.001 55.999,229.603 55.999,168.001 Z" id="Shape"></path></g></g></svg>
                    </CSSTransition>
                    <form action="." style={{width: "100%"}}>
                      <input
                        type="search"
                        id="glossary-search-input"
                        style={{backgroundColor: "transparent"}}
                        value={searchQueryText}
                        autoCapitalize="none"
                        autoCorrect="false"
                        autoComplete="off"
                        spellCheck={false}
                        onFocus={() => {
                          this.setState({searchBarHasFocused: true, searchBarFocused: true})
                        }}
                        onBlur={() => {
                          this.setState({searchBarFocused: false})
                        }}
                        onKeyDown={(e) => {
                          if(e.key === 'Enter') {
                            document.getElementById("glossary-search-input").blur()
                          }
                        }}
                        onChange={(e) => {
                          const updatedQuery = e.target.value.slice(0, 100)
                          this.setState({searchQueryText: updatedQuery})
                        }}
                        onPaste={(e) => {
                          const updatedQuery = e.target.value.slice(0, 100)
                          this.setState({searchQueryText: updatedQuery})
                        }}
                        placeholder={i18next.t('type_to_filter')}
                        className={"application-search-input"}
                      />
                    </form>
                  </div>
                </div>
              </div>
              <div style={{minWidth: "100%", display: "flex", justifyContent: "start", margin: "2vh 0px"}}>
                <div className="disable-scrollbars" style={{overflowX: "scroll", padding: "2px 0px", paddingInlineEnd: "calc(max(7.5vw, 50vw - 350px))", paddingInlineStart: "calc(max(7.5vw, 50vw - 350px))", overflowY: "visible", whiteSpace: "nowrap"}}>
                  {articleCategoryObjects.map(view => {
                    const isSelected = (view.key === selectedCategory)
                    return (
                      <div
                        key={view.key}
                        style={{padding: "4px 8px", textTransform: "uppercase", margin: "15px 0px", marginInlineEnd: "15px", display: "inline-block", cursor: "pointer", color: isSelected ? "#FFFFFF" : "#000000", backgroundColor: isSelected ? "#000000" : "#FFFFFF", boxShadow: "0px 1px 1.5px 0.5px #dddddd", textTransform: "uppercase", fontFamily: "Futura", fontSize: "min(4.5vw, 18px)"}}
                        onClick={() => this.setState({selectedCategory: (view.key !== selectedCategory) ? view.key : ""})}
                      >
                        {view.value}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div style={{color: "black", width: "100%", display: "flex", justifyContent: "center", alignItems: "top"}}>
                <div style={{width: "85%", maxWidth: 700}}>
                  <div style={{marginBottom: "4vh", fontSize: "min(5vw, 20px)", textAlign: "start", fontStyle: "italic", fontFamily: "Futura Medium"}}>
                    {`${i18next.t('results')}: ${finalFilteredArticlePreviews.length}`}
                  </div>
                  <div style={{marginBottom: "8vh"}}>
                    {finalFilteredArticlePreviews.map(rec => (
                      <StaticArticleRecommendation
                        articleData={rec}
                        languageCode={languageCode}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{textAlign: "start", width: "100vw"}}>
              <div style={{marginTop: 15, padding: 15, fontSize: "min(3.5vw, 16px)", fontFamily: "Futura", display: "flex", alignItems: "center", "justifyContent": "space-between"}}>
                <div style={{display: "inline-block"}}>
                  <span style={{color: "#c1c4d2"}}>
                    {i18next.t('copyright_neurofit')}
                  </span>
                  <span style={{display: "inline-block", color: "#c1c4d2", margin: "0px 0.5em", fontSize: "min(2vw, 10px)"}}>
                    {"•"}
                  </span>
                  <span style={{color: "#c1c4d2"}}>
                    {i18next.t('all_rights_reserved')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LearnLocalizedSitemapPage;