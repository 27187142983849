import React from 'react'

import {Helmet} from "react-helmet";

const NeuroFitHelmet = ({
  title,
  description,
  href,
  useNoindex
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta description={description} />
    <link rel="canonical" href={href} />
    {useNoindex && <meta content="noindex" name="robots" />}
  </Helmet>
)

export default NeuroFitHelmet