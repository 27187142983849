import i18next from 'i18next'

export const StillnessState = {
  min: 0,
  max_exclusive: 60,
  name: "stillness",
  display_name: "Stillness",
  title_name: "stillness",
  color: "#93E29A",
  color_pastel: "#93E29A",
  emoji: "🌿",
  colorWithOpacity: opacity => `rgba(147, 226, 154, ${opacity})`
}
export const VentralVagalState = {
  min: 60,
  max_exclusive: 120,
  name: "parasympathetic",
  display_name: "Ventral Vagal",
  title_name: "ventral",
  color: "#FFD634",
  color_pastel: "#FFD634",
  emoji: "💫",
  colorWithOpacity: opacity => `rgba(255, 214, 52, ${opacity})`
}
export const PlayState = {
  min: 120,
  max_exclusive: 180,
  name: "play",
  title_name: "play",
  display_name: "Play",
  color: "#FFAA32",
  color_pastel: "#FFAA32",
  emoji: "⚡️",
  colorWithOpacity: opacity => `rgba(255, 170, 50, ${opacity})`
}
export const SympatheticState = {
  min: 180,
  max_exclusive: 240,
  name: "sympathetic",
  title_name: "sympathetic",
  display_name: "Sympathetic",
  color: "#FF7CBC",
  color_pastel: "#FF7CBC",
  emoji: "🔥",
  colorWithOpacity: opacity => `rgba(255, 124, 188, ${opacity})`
}
export const OverwhelmState = {
  min: 240,
  max_exclusive: 300,
  name: "overwhelm",
  title_name: "overwhelm",
  display_name: "Overwhelm",
  color: "#9EA0DA",
  color_pastel: "#9EA0DA",
  emoji: "🌪️",
  colorWithOpacity: opacity => `rgba(158, 160, 218, ${opacity})`
}
export const DorsalVagalState = {
  min: 300,
  max_exclusive: 360,
  name: "dorsal_vagal",
  title_name: "dorsal",
  display_name: "Dorsal Vagal",
  color: "#1ACFF2",
  color_pastel: "#1ACFF2",
  emoji: "❄️",
  colorWithOpacity: opacity => `rgba(26, 207, 242, ${opacity})`
}

export const nervousSystemStates = [
  StillnessState,
  VentralVagalState,
  PlayState,
  SympatheticState,
  OverwhelmState,
  DorsalVagalState
]

const dysregulatedStateSuggestions = {
  "dorsal_vagal": {
    "text": "Suggested For You:",
    "state": "balance",
    "color": "#FFD634",
    "recommended_state_name": "parasympathetic"
  },
  "sympathetic": {
    "text": "Suggested For You:",
    "state": "stillness",
    "color": "#93E29A",
    "recommended_state_name": "stillness"
  },
  "overwhelm": {
    "text": "Suggested For You:",
    "state": "activity",
    "color": "#FFAA32",
    "recommended_state_name": "play"
  },
}

const liveRegulationSuggestions = {
  "play": {
    "text": "Suggested For You:",
    "state": "activity",
    "color": "#FFAA32",
    "hideInfoButton": true,
    "recommended_state_name": "play"
  },
  "stillness": {
    "text": "Suggested For You:",
    "state": "stillness",
    "color": "#93E29A",
    "hideInfoButton": true,
    "recommended_state_name": "stillness"
  },
  "parasympathetic": {
    "text": "Suggested For You:",
    "state": "balance",
    "color": "#FFD634",
    "hideInfoButton": true,
    "recommended_state_name": "parasympathetic"
  },
}

const ProgramWeekTitleMap = {
  1: "Foundation",
  2: "Clearing",
  3: "Precision",
  4: "Momentum",
  5: "Balance",
  6: "Renewal"
}

export const getTitleForProgramWeek = programWeek => {
  return ProgramWeekTitleMap[programWeek] || ""
}

export const isDysregulatedState = stateName => {
  return (stateName === "dorsal_vagal") || (stateName === "sympathetic") || (stateName === "overwhelm")
}

export const isRecommendedForState = (stateName, suggestedStateName) => {
  if (stateName === "dorsal_vagal") {
    return suggestedStateName === "play"
  } else if (stateName === "overwhelm") {
    return suggestedStateName === "play"
  } else if (stateName === "sympathetic") {
    return (suggestedStateName === "stillness") || (suggestedStateName === "play")
  }
}

export const getCurrentStateMeta = stateName => {
  for (let i = 0; i < nervousSystemStates.length; i++) {
    if (nervousSystemStates[i].name === stateName) {
      return nervousSystemStates[i]
    }
  }
}

export const getDisplayNameForState = stateName => {
  const stateMeta = getCurrentStateMeta(stateName)
  return (!!(stateMeta)) ? stateMeta.display_name : stateName
}

export const getColorForState = stateName => {
  const stateMeta = getCurrentStateMeta(stateName)
  return (!!(stateMeta)) ? stateMeta.color : "#000000"
}

export const getNameForState = deg => {
  for (let i = 0; i < nervousSystemStates.length; i++) {
    if (deg >= nervousSystemStates[i].min && deg < nervousSystemStates[i].max_exclusive) {
      return nervousSystemStates[i].name
    }
  }
}

export const getRegulatedStateCta = stateName => {
  const regulatedStateCTAs = {
    "parasympathetic": {
      "play": i18next.t('movement_shift_energy'),
      "parasympathetic": i18next.t('anchoring_into_safety'),
      "stillness": i18next.t('slowing_down_body_and_mind'),
    },
    "play": {
      "play": i18next.t('releasing_pent_up_energy'),
      "parasympathetic": i18next.t('coming_into_balance'),
      "stillness": i18next.t('calming_the_body'),
    },
    "stillness": {
      "play": i18next.t('movement_shift_energy'),
      "parasympathetic": i18next.t('coming_into_balance'),
      "stillness": i18next.t('anchoring_into_calm'),
    },
    "sympathetic": {
      "play": i18next.t('releasing_pent_up_energy'),
      "parasympathetic": i18next.t('coming_into_balance'),
      "stillness": i18next.t('calming_the_body'),
    },
    "dorsal_vagal": {
      "play": i18next.t('movement_increase_energy'),
      "parasympathetic": i18next.t('coming_into_balance'),
      "stillness": i18next.t('slowing_down_calm'),
    },
    "overwhelm": {
      "play": i18next.t('releasing_shifting_energy'),
      "parasympathetic": i18next.t("balancing_body_mind"),
      "stillness": i18next.t('slowing_down_body_and_mind'),
    },
  }
  return regulatedStateCTAs[stateName]
}

export const getRecommendationForDysregulatedState = (stateName, liveStateRecommendations) => {
  console.log(liveStateRecommendations)
  if (!!(liveStateRecommendations) && liveStateRecommendations.length > 0) {
    return liveRegulationSuggestions[Object.keys(liveStateRecommendations[0])[0]]
  }
  return dysregulatedStateSuggestions[stateName]
}